<template>
  <div
    class="card column"
    :style="{ 'flex': flex }"
    :class="[
      classBasedOnTitle,
      chartOrGrid,
      className,
      { 'tablet-full': tabletFull },
      { 'tablet-half': tabletHalf },
      { 'auto-flex-basis': autoFlexBasis },
    ]"
  >
    <div
      class="card__title"
      v-if="title"
    >
      <h3>{{ title }}</h3>
      <!-- <span class="space"></span> -->
      <span class="slot">
        <slot name="upper-right"></slot>
      </span>

    </div>
    <div
      class="card__content"
      :class="flexDirection"
      :style="{ gap: contentGap + 'rem' }"
    >
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import { stringToClassName } from "@/helpers/stringFormatter.js";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    flex: {
      type: Number,
      default: 1,
    },
    flexDirection: {
      type: String,
      default: "row",
    },
    chartOrGrid: {
      type: String,
      default: "chart-layout",
    },
    className: {
      type: String,
      default: null,
    },
    tabletFull: {
      type: Boolean,
      default: true,
    },
    tabletHalf: {
      type: Boolean,
      default: false,
    },
    autoFlexBasis: {
      type: Boolean,
      default: false,
    },
    contentGap: {
      type: Number,
      default: 1
    }
  },
  computed: {
    classBasedOnTitle() {
      return stringToClassName(this.title);
    },
  },
  components: {},
};
</script>

<style lang="scss">
.card__title {
  display: flex;

  >h3 {
    //display: inline-block !important;
    width: auto !important;
  }

  // >span.space {
  //   flex-grow: 1;
  // }

  >span.slot {
    width: auto;
  }
}
</style>
